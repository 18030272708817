import { createStore } from 'vuex'
import { chart } from './chart'
import { account } from './account'
import { config } from './config'
import { currencies_prices } from './currencies_prices'

export default createStore({
  modules: {
    chart, account, config, currencies_prices,
  }
})
