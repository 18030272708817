
import PlaidLink from '../../components/Plaid/PlaidLink.vue';
import { eventEmitter } from '@/utils/helpers';
import { defineComponent } from 'vue';

export default defineComponent({
    components: {
        PlaidLink
    },
    data() {
        return {
            showPlaidLinkContainer: false,
        }
    },
    created() {
        eventEmitter.on('openPlaidLink', async (event: any) => {
            this.showPlaidLinkContainer = false;
            if (!await this.$store.dispatch('startPlaidSubscription')) {
                throw new Error('Failed to get link');
            }
            this.$nextTick(() => {
                this.showPlaidLinkContainer = true;
            })
        })
    },
    methods: {
        onSuccess(public_token: any) {
            this.$store.dispatch('authPlaidSubscription', { public_token })
                .then(() => this.$toast.success('Confirmed'));
        },
        onLoad(v: any) {
            console.log(v);
        },
    }
})
