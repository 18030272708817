import api from '@/utils/api';

export const currencies_prices = {
  state: {
    possible_tickers: {
      1: {
        id: 1,
        name: 'BTC/USDT',
      },
      2: {
        id: 2,
        name: 'ETH/USDT',
      },
    },
  },
  getters: {
    getPossibleTickers(state: any) {
      return state.possible_tickers;
    },
  },
  mutations: {
    UPDATE_POSSIBLE_TICKERS(state: any, { rootGetters }: any) {
      const result: any = {};

      state.possible_tickers = { ...result };
    }
  },
  actions: {
    loadAllPrices(context: any) {
      if (!context.getters.getCurrentTickerId) {
        context.dispatch('setCurrentTicker', { id: Object.keys(context.getters.getPossibleTickers)[0] })
      }
      return;
    },
  },
  modules: {
  }
}