import api, { isLoggedIn, logOut } from '@/utils/api';

export const account = {
  state: {
    isLoggedIn: false,
    passwordReseting: { status: false, userData: null },
    currentPlaidLink: null,
    changesList: [],
    stripeSubscriptionStatus: false,
    cryptoWallets: {},
  },
  getters: {
    isLoggedIn(state: any) {
      return state.isLoggedIn;
    },
    getPasswordReseting: (state: any) => state.passwordReseting,
    getCurrentPlaidLink: (state: any) => state.currentPlaidLink,
    getCurrentStripeLink: (state: any) => state.currentStripeLink,
    getChangesList: (state: any) => state.changesList,
    getStripeSubscriptionIsActive: (state: any) => state.stripeSubscriptionStatus,
    getWalletAddress: (state: any) => (network: 'BSC') => state.cryptoWallets[network] ?? null,
  },
  mutations: {
    UPDATE_IS_LOGGED_IN(state: any, val: boolean) {
      state.isLoggedIn = val;
    },
    UPDATE_PASSWORD_RESETING(state: any, val: { status: boolean, userData: any }) {
      state.passwordReseting.status = val.status;
      state.passwordReseting.userData = val.userData;
    },
    SET_CURRENT_PLAID_LINK(state: any, value: string) {
      state.currentPlaidLink = value;
    },
    SET_CURRENT_CHANGES_LIST(state: any, value: any[]) {
      state.changesList = value;
    },
    SET_STRIPE_SUBSCRIPTION(state: any, value: boolean) {
      state.stripeSubscriptionStatus = value;
    },
    SET_CURRENT_STRIPE_LINK(state: any, value: string) {
      state.currentStripeLink = value;
    },
    SET_WALLET(state: any, data: { network: 'BSC', address: string }) {
      state.cryptoWallets[data.network] = data.address;
    },
  },
  actions: {
    setIsLoggedIn(context: any) {
      context.commit('UPDATE_IS_LOGGED_IN', isLoggedIn());
    },
    startPlaidSubscription(context: any) {
      return api({ url: '/users/banks/plaid-subsription', data: {}, method: 'GET' })
        .then((response: any) => {
          if (response.data.status && response.data.data.auth_token) {
            context.commit('SET_CURRENT_PLAID_LINK', response.data.data.auth_token);
            return true;
          }
        })
    },
    loadAllChanges(context: any) {
      return api({ url: '/users/banks/changes-list', data: {}, method: 'GET' })
        .then((response: any) => {
          if (response.data.status && response.data.data) {
            context.commit('SET_CURRENT_CHANGES_LIST', response.data.data);
            return true;
          }
        })

    },
    loadStripeSubscriptionStatus(context: any) {
      return api({ url: '/users/banks/is-stripe-subscribed', data: {}, method: 'GET' })
        .then((response: any) => {
          if (response.data.status) {
            context.commit('SET_STRIPE_SUBSCRIPTION', response.data.data);
            return true;
          }
        })
    },
    startStripeSubscription(context: any) {
      return api({ url: '/users/banks/stripe-subsription', data: {}, method: 'GET' })
        .then((response: any) => {
          if (response.data.status && response.data.data.url) {
            context.commit('SET_CURRENT_STRIPE_LINK', response.data.data.url);
            return true;
          }
        })
    },
    authPlaidSubscription(context: any, data: { public_token: string }) {
      return api({ url: '/users/banks/plaid-subsription', data: { public_token: data.public_token }, method: 'POST' });
    },
    saveBSCWallet(context: any, data: { address: string }) {
      return api({ url: '/users/cold-wallets/set-for-net', data: { address: data.address, network: 'BSC' }, method: 'POST' });
    },
    getBSCWallet(context: any, data:any) {
      return api({ url: '/users/cold-wallets/get-for-net', params: { network: 'BSC' }, method: 'GET' }).then((r:any) => {
        context.commit('SET_WALLET', { network: r.data.data.network_type, address: r.data.data.address })
      })
    },
    startResetPassword(context: any, data: { email: string } | { email: string, code: number, new_password: string }) {
      const _data: any = data;
      if (!_data.code || !_data.new_password) {
        delete _data.code;
        delete _data.new_password;
      } else {
        _data.code = +_data.code;
      }
      return api({ url: '/users/password-reset', data: _data, method: 'POST' })
        .then((response: any) => {
          if (response.status === 201 && response.data.status && response.data.code_sent) {
            context.commit('UPDATE_PASSWORD_RESETING', { status: true, userData: data });
            return true;
          }
          if (response.status === 201 && response.data.status) {
            context.commit('UPDATE_PASSWORD_RESETING', { status: false });
            return true;
          }
          context.commit('UPDATE_PASSWORD_RESETING', { status: false });
          throw new Error(response.data.message);
        })
        .catch((e: any) => {
          if (e && e?.data?.statusCode === 400) {
            if (e?.data?.message === 'Code is not correct. You have 0 tries more' || e?.data?.message === 'Code is not active' || e?.data?.message === 'Code is expired') {
              context.commit('UPDATE_PASSWORD_RESETING', { status: false });
            }
            throw new Error(e?.data?.message || 'Unknown');
          }
          throw e;
        })
    },
    logout(context: any) {
      logOut();
      context.dispatch('setIsLoggedIn');
    },
  },
  modules: {
  }
}