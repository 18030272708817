import { createStore } from 'vuex'

export const chart = {
  state: {
    chartData: {}
  },
  getters: {
    chartData(state:any) {
      return state.chartData
    }
  },
  mutations: {
    UPDATE_CHART_DATA(state:any, val:any) {
      state.chartData = val;
    },
  },
  actions: {
    updateChartData(context:any, data:any) {
      context.commit('UPDATE_CHART_DATA', data);
    }, 
  },
  modules: {
  }
}