import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PlaidLink = _resolveComponent("PlaidLink")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.showPlaidLinkContainer)
      ? (_openBlock(), _createBlock(_component_PlaidLink, {
          key: 0,
          token: _ctx.$store.getters.getCurrentPlaidLink,
          onSuccess: _ctx.onSuccess,
          onLoad: _ctx.onLoad
        }, null, 8, ["token", "onSuccess", "onLoad"]))
      : _createCommentVNode("", true)
  ]))
}